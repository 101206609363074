<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <v-card>
          <v-card-title>
            Пользователи
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="is_fake"
                  label="Показать ютуберов"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="admin"
                  label="Показать админов"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="moderator"
                  label="Показать модераторов"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-text-field
                  v-model="search"
                  label="Поиск по id, login, email, nick"
                  single-line
                  hide-details
                />
              </v-col>
              <v-col>
                <v-btn color="primary" large @click="searchUsers">
                  Показать
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-data-table
          @click:row="(item, row) => $router.push(`/users/${item.id}`)"
          :headers="headers"
          :items="users"
          :loading="loading"
          :items-per-page="25"
          loading-text="Загрузка... Пожалуйста подождите"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template v-slot:item.balance="{ item }">
            {{ (item.balance / 100).toFixed(2) }}
          </template>
          <template v-slot:item.lastLogin="{ item }">
            {{ item.lastLogin | moment("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template v-slot:item.is_fake="{ item }">
            <v-simple-checkbox
              color="green"
              disabled
              :value="item.is_fake"
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.moderator="{ item }">
            <v-simple-checkbox
              color="green"
              disabled
              :value="item.moderator"
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.admin="{ item }">
            <v-simple-checkbox
              color="green"
              disabled
              :value="item.admin"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: function() {
    return {
      search: "",
      admin: false,
      moderator: false,
      is_fake: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Логин", value: "login" },
        { text: "Ник", value: "nick" },
        { text: "email", value: "email" },
        { text: "Баланс", value: "balance" },
        { text: "Зарегистрирован", value: "createdAt" },
        { text: "Последний визит", value: "lastLogin" },
        { text: "Ютубер", value: "is_fake" },
        { text: "Админ", value: "admin" },
        { text: "Модератор", value: "moderator" },
        { text: "", value: "settings" }
      ],
      options: {
        page: 1,
        itemsPerPage: 25
      },
      params: {
        orderBy: {
          id: "desc"
        },
        take: 400,
        where: {}
      }
    };
  },
  watch: {
    admin(val) {
      if (val) {
        this.params.where = { ...this.params.where, admin: val };
      } else {
        delete this.params.where.admin;
      }
    },
    is_fake(val) {
      if (val) {
        this.params.where = { ...this.params.where, is_fake: val };
      } else {
        delete this.params.where.is_fake;
      }
    },
    moderator(val) {
      if (val) {
        this.params.where = { ...this.params.where, moderator: val };
      } else {
        delete this.params.where.moderator;
      }
    },
    search(val) {
      const search = val.toString().trim();
      if (search.length > 0) {
        this.params.where = {
          ...this.params.where,
          OR: [
            {
              id: {
                equals: isNaN(parseInt(search)) ? 0 : parseInt(search)
              }
            },
            {
              hashids: {
                contains: search
              }
            },
            {
              login: {
                contains: search
              }
            },
            {
              email: {
                contains: search
              }
            },
            {
              nick: {
                contains: search
              }
            }
          ]
        };
      } else {
        delete this.params.where.OR;
      }
    }
  },
  computed: {
    ...mapGetters("users", ["users", "loading"])
  },
  methods: {
    ...mapActions("users", ["findMany"]),
    searchUsers() {
      this.findMany(this.params);
    }
  },
  created: function() {
    this.findMany(this.params);
  }
};
</script>
<style>
tr {
  cursor: pointer !important;
}
</style>
